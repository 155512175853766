// console.log("main js");
// /--------------------------------------
//   buttom
// /--------------------------------------
let hamMenuBtn = document.getElementById("hamburger");
let hamMenu = document.getElementById("hamMenu");
let closeBtn = document.getElementById("close");

let news_modal = document.getElementById("news_modal");
let news_bg = document.getElementById("news_bg");
let news_close = document.getElementById("news_close");
let list_news02 = document.getElementById("list_news02");
// let sp_movie_close = document.getElementById("sp_movie_close");
// let sp_movie = document.getElementById("sp_movie");

// console.log("test");
// console.log(hamMenuBtn);
hamMenuBtn.addEventListener( "click", function(){
  console.log("menu");
  hamMenu.classList.add("hamShow");
} );

closeBtn.addEventListener( "click", function(){
  hamMenu.classList.remove("hamShow");
} );




// open
list_news02.addEventListener( "click", function(){
  console.log("news02_open");
  news_modal.classList.add("news02_open");
} );
// close
news_bg.addEventListener( "click", function(){
  console.log("news02_open");
  news_modal.classList.remove("news02_open");
} );
news_close.addEventListener( "click", function(){
  console.log("news02_open");
  news_modal.classList.remove("news02_open");
} );


//   concept  text more
// /--------------------------------------
let more_btn = document.getElementById("more_btn");
let detail = document.getElementById("detail");
